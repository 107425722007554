
/* global environment */
/* global releaseVersion */
/* global agencyFlowDatadogRumEnabled */

import { datadogRum } from '@datadog/browser-rum';

export const initializeDatadogRum = () => {
  if (agencyFlowDatadogRumEnabled !== 'true') { return; }
  datadogRum.init({
    applicationId: 'be6702c9-89c5-46e8-a2f5-3d71d8ae8b68',
    clientToken: 'pub6dc2f2093b85545e5143251493126d2b',
    site: 'datadoghq.com',
    service: 'quote.root.com',
    env: environment,
    version: releaseVersion,
    sessionSampleRate: 100,
    // decrease this after initial launch monitoring period is over -- it was 20 before
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};

window.setDatadogUser = (user) => {
  if (user !== null) {
    datadogRum.setUser(user);
  }
};
